export default {
  BOOKING_LIST: "List of reservations",
  PENDING_BOOKING_LIST: "List of pending reservations",
  ADD_BOOKING: "Add reservation",
  BOOKING_ADDED: "Reservation confirmed",
  EDIT_BOOKING: "Modify reservation",
  BOOKING_UPDATED: "Reservation updated",
  DELETE_THIS_BOOKING: "Delete this reservation?",
  BOOKING_DELETED: "Reservation deleted",
  ADULTS_CAPACITY: "Adultes",
  PETS_CAPACITY: "Pets",
  CHILDREN_CAPACITY: "Children",
  BOOK_SPOT: "Book a spot",
  STARTING_FROM: "Starting from",
  PRICE_PER_NIGHT: "per night",
  AVAILABILITY: "Availability",
  AVAILABLE: "Available",
  NOT_AVAILABLE: "Not available",
  PARTIALLY_AVAILABLE: "Partially available",
  FILTER_BY_SPOT_CATEGORY: "Pitch or site",
  FILTER_BY_SPOT_TYPE: "Service",
  FILTER_BY_SPOT_LENGTH: "Equipment dimensions",
  FILTER_BY_EQUIPMENT: "Equipment",
  UNIT_FEET: "feet",
  SPOTS_LIST_FOUND: "{total} sites found",
  BOOKING_CODE: "Confirmation code",
  DURATION: "Number of nights",
  PRICE_PER_DAY: "Price/day",
  ARRIVING_AT: "Arrival",
  LEAVING_AT: "Leaving",
  EXISTING_CUSTOMER: "Client existant",
  CREATE_CUSTOMER: "Nouveau client",
  SECURE_BOOKING: "Secuer",
  ADD_TO_CART: "Add",
  SPECIAL_REQUEST: "Customer special request",
  INTERNAL_NOTE: "Internal note",
  SELECT_CONFIRMATION_EXPIRATION_DATE: "Select an expiration date for confirmation",
  SEND_TO_CUSTOMER_EMAIL: "Send to customer's email address",
  CONFIRM_AND_SEND: "Confirm and send",
};
